@use '../../assets/styles/mixins' as mixin;

.navBar {
  background-color: #f0f0f0;
  justify-content: center;
  align-items: center;
  width: auto;
  height: auto;
}

.nav {
  padding-top: 20px;
  display: flex;
  width: 600px;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  -webkit-user-select: none;
  user-select: none;
  z-index: 1;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;

  @include mixin.sm {
    padding-top: 50px;
    padding-bottom: 50px;
    width: 450px;
    border-radius: 35px;
  }

  @include mixin.xs {
    padding-top: 50px;
    width: 300px;
    height: 60px;
    border-radius: 30px;
    padding-bottom: 50px;
  }
}

.navlinks {
  display: flex;
  flex-wrap: wrap; // Permet aux enfants de passer à la ligne si nécessaire
  justify-content: center;
  align-items: center;
  gap: 10px;

  @include mixin.xs {
    padding-top: 30px;
    gap: 5px; // Réduire l'espace entre les éléments pour les petits écrans
  }
}


.navLinksItem {
  cursor: pointer;
  color: #001436;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-style: normal;
  text-decoration: none;
  height: 40px;
  padding-left: 20px;
  font-weight: 400;
  line-height: 18px;
  white-space: nowrap;

  @include mixin.xs {
    padding: 10px 5px;
    font-size: 14px;
  }

  &:hover {
    color: #ef4838;
  }

  @include mixin.sm {
    padding: 10px 5px;
    font-size: 20px;
  }

  @include mixin.xs {
    font-size: 14px;
  }
}

.vertical {
  display: inline; /* Ou inline-block pour appliquer padding et margin */
  padding-left: 20px;
  font-size: 18px;
  color: #001436;
  margin: 0; /* Supprimer le margin par défaut du paragraphe */
}

.navLinksItem.active {
  width: auto;
  height: auto;
  padding: 6px 15px 6px 15px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #002349;
  color: white !important;
  margin-left: 15px;

  @include mixin.xs {
    margin-left: 0;
  }

  @include mixin.sm {
    margin-left: 0;
  }
}
