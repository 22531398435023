.lessonCard {
  width: 307.33px;
  height: 250px;
  border: 1px solid rgb(201, 200, 200);
  border-radius: 10px;
  font-family: 'Inter', sans-serif;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.lessonCard:hover {
  transform: scale(1.02); /* Légère mise en avant sur hover */
}

.imageContainer {
  height: 50%; /* La moitié de la hauteur de la carte */
  width: 100%;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Assure que l'image remplit l'espace sans se déformer */
}

.textContainer {
  padding: 15px; /* Espacement autour du texte */
  height: 50%; /* La moitié inférieure de la carte */
  display: flex;
  flex-direction: column;
  justify-content: flex-start; /* Aligne les éléments en haut du conteneur */
}

.title {
  font-size: 16px;
  color: #002349;
  margin: 0 0 5px 0;
  font-weight: bold;
}

.professor {
  font-size: 12px;
  font-weight: 400;
  color: #002349;
  margin-top: 5px;
}

.professor span {
  font-weight: 600;
  color: #002349;
}



.fa {
  color: #002349;
  padding-top: 10px;
}

.divTime {
  position: absolute;
  bottom: 0px;
  left: 15px;
  display: flex;
  align-items: center; /* Aligne verticalement l'icône et le texte */
  justify-content: space-between; /* Ajoute de l'espace entre les éléments */
  width: calc(100% - 30px); /* Assure que le div prend la largeur complète */
}

.time {
  color: #002349;
  font-size: 13px;
  font-weight: bold;
  margin-right: auto; /* Pousse les éléments suivants à droite */
}

.level, .category {
  color: #002349;
  font-size: 13px;
  font-weight: bold;
}

.level {
  margin-left: auto; /* Pousse le niveau vers la droite */
}

.category {
  margin-left: 5px; /* Laisse un petit espace entre le niveau et la catégorie */
}
