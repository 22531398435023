@use '../../assets/styles/mixins' as mixin;

h1 {
  color: white;
  font-size: 75px;
  font-family: var(--font-family);
  @include mixin.sm {
    font-size: 55px;
  }

  @include mixin.md {
    font-size: 65px;
  }

  @include mixin.xs {
    font-size: 45px;
  }
}

h2 {
  color: #7477ED;
  font-size: 75px;
  font-family: var(--font-family);
  @include mixin.sm {
    font-size: 51px;
  }

  @include mixin.md {
    font-size: 65px;
  }
  @include mixin.xs {
    font-size: 34px;
  }
}

h3 {
  color: white;
  font-size: 35px;
}

p {
  color: white;
  font-size: 24px;
}


.progressBar {
  width: 70%;
  margin: 0 auto;
  position: relative;
  background-color: #707070;
  height: 1px;
}

.homepage {
  background-color: #f0f0f0;
  justify-content: center;
  display: flex;
  align-items: center;
}
