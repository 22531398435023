.loginContainer {
  max-width: 400px;
  margin: 9px auto 70px auto;
  padding: 20px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.log {
  padding: 50px;
}

.h1 {
  text-align: center;
  margin-bottom: 20px;
  color: #002349;
}

.loginForm {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formGroup {
  margin-bottom: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.forgotPasswordLink > a {
  color: black;
  text-align: center;
  font-size: 13px;
}

label {
  margin-bottom: 5px;
  display: block;
  font-weight: bold;
  text-align: center;
}

.inputField {
  width: 70%;
  padding: 10px;
  border-radius: 50px;
  border: 1px solid #ccc;
  margin: 0 auto;
}

.loginButton {
  padding: 10px;
  background-color: #002349;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 10px;
  width: 70%;
  margin: 10px auto;
  border-radius: 50px;
}

.loginButton:hover {
  background-color: #013872;
}

.orSeparator {
  text-align: center;
  margin: 20px 0;
  font-weight: bold;
  color: #777;
}

.googleButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  background-color: transparent;
  border: 1px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
  width: 70%;
  margin: 0 auto;
  border-radius: 50px;
}

.googleButton:hover {
  background-color: #f5f5f5;
  border: 1px solid #002349;
}

.googleLogo {
  width: 38px;
  height: 38px;
  margin-right: 10px;
}
