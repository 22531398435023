@use '../../assets/styles/mixins' as mixin;
@use '../../assets/styles/debug';
@use '../../assets/styles/index.scss';

.Lessons {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 50px;

}

.titleContainer {
  padding-left: 140px;
  margin-bottom: 20px;

  @include mixin.lg {
    padding-left: 30px;
  }

  @include mixin.md-lt {
    padding-left: 90px;
  }

  @include mixin.sm-lt {
    padding-left: 70px;
  }

  @include mixin.xs {
    padding-left: 40px;
  }
}

.h1 {
  color: #002349;
  font-size: 30px;
  font-family: 'Inter', sans-serif;
}

.h2 {
  color: #002349;
  font-size: 22.5px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}

.lessonList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  padding-bottom: 60px;
}

.pagination {
  display: flex;
  color: #002349;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.paginationButton {
  margin: 0 10px;
  color: #002349;
  border: 2px solid #002349;
  border-radius: 50px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;
}

.paginationButton:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.pageInfo {
  font-size: 16px;
}

.filterButtons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  h3 {
    margin-top: 20px;
  }

  .filterButton {
    color: #002349;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 50px;
  }

  .filterButton.active {
    border: 3px solid #002349;
    color: #002349;
    font-weight: bold;
  }

  .categoryButtons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
  }

  .levelButtons {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }
}
