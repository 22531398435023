@use '../../assets/styles/mixins' as mixin;
@use '../../assets/styles/debug';
@use '../../assets/styles/index.scss';


.h1prof{
  color: #001436;
  font-family: 'Inter';
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 50px;
  @include mixin.sm {
    font-size: 55px;
  }
  text-align: center;
}

.ProfessorJJB1 {
  background-color: #f0f0f0;
  margin: 70px 70px  auto;
  @include mixin.xs {
    margin: 70px auto  auto;
  }
}

.pJJB {
  color: #001436;
  width: auto;
  margin: 60px;
  font-family: "Inter", sans-serif;
  text-align: justify;
  justify-content: center;
  font-size: 15.63px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.info {
  color: #001436;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  margin-left: 30px;
  padding-top: 0;
  margin-top: 0;
}

.info1 {
  color: #001436;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  margin-left: 30px;
  margin-top: 30px;
  
}

.Thiago {
  width: 300px;
  height: 310px;
  border-radius: 50%;
  border: 7px solid #002349;
}

.feli {
  width: 300px;
  height: 310px;
  border-radius: 50%;
  border: 7px solid #002349;
  @include mixin.xs {
  width: 300px;
  height: 310px;
  border-radius: 50%;
  border: 7px solid #002349;
  }
}

.professsor {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-bottom: 100px;

  @include mixin.sm {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  @include mixin.xs {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  @include mixin.md {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  @include mixin.md-lt {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
}

.professsorInfo {
  width: 224px;
  height: 192px;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 10px;
  border: 2px solid #002349;
  background: rgba(255, 255, 255, 0.00);
  box-shadow: 0px 4px 6px -1px rgba(0, 20, 54, 0.10), 0px 2px 4px -2px rgba(0, 20, 54, 0.10);
}
