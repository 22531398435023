@use './assets/styles/mixins' as mixin;


html, body {
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
  font-family: 'Inter', sans-serif;
}

.appContainer {
  min-height: 100vh; // Assure que l'élément prend toute la hauteur de la fenêtre
  display: flex;
  flex-direction: column;
  background-color: #f0f0f0;
  padding: 0;
  margin: 0;
}

/* Le contenu principal prend tout l'espace disponible */
.content {
  flex-grow: 1;  // Pousse le contenu vers le bas, laissant le footer en bas
}

.popup {
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -20%);
  background-color: white;
  border: 1px solid #ef4838;
  padding: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  border-radius: 8px;
  overflow: hidden; /* Pour s'assurer que les feux d'artifice restent à l'intérieur */
}

.popupContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; /* Permet aux feux d'artifice de se positionner par rapport à cette div */
}

.strong {
  color: #ef4838;
}

.popupPara {
  margin-bottom: 10px;
  font-size: 16px;
  color: black;
  text-align: center;

  @include mixin.xs {
    text-align: justify;
  }
}

button {
  padding: 5px 10px;
  cursor: pointer;
}

.btnpop {
  width: 100px;
  height: 47px;
  border-radius: 50px;
  background: #002349;
  border: none;
  justify-content: center;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;
}


/* Conteneur des feux d'artifice */
.fireworksContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1; /* Derrière le contenu */
}

.firework {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  animation: explode 1.5s infinite ease-out;
  top: calc(var(--random-y) * 100%); /* Positionner entre 0 et 100% de la hauteur */
  left: calc(var(--random-x) * 100%); /* Positionner entre 0 et 100% de la largeur */
  background-color: var(--random-color, gold);
  transform-origin: center;
}

/* Couleurs aléatoires */
.firework:nth-child(2n) {
  background-color: red;
}
.firework:nth-child(3n) {
  background-color: blue;
}
.firework:nth-child(4n) {
  background-color: green;
}
.firework:nth-child(5n) {
  background-color: purple;
}

@keyframes explode {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}
