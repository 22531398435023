.forgot {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.forgoth1 {
    padding-top: 50px;
    text-align: center;
    color: #002349;
}

.form {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
}

.labelforgot {
    margin-right: 10px;
}

.buttonforgot {
    margin-left: 10px;
    width: auto;
    height: auto;
    padding: 8px;
    border-radius: 50px;
    background: #002349;
    border: none;
    justify-content: center;
    color: #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    cursor: pointer;
}