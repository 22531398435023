@use '../../assets/styles/mixins' as mixin;
@use '../../assets/styles/debug';
@use '../../assets/styles/index.scss';

.h1 {
  color: #002349;
  text-align: center;
  margin-top: 0;
  padding-bottom: 50px;
}

.offers {
  padding-top: 40px;
  padding-bottom: 40px;
}

.offer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 50px; 
}

.offerInfo {
  width: 260px;
  height: 260px;
  padding: 20px;
  border-radius: 15px;
  border: 2px solid #002349;
  background-color: #f9f9f9;
  box-shadow: 0px 8px 16px rgba(0, 20, 54, 0.1), 0px 4px 8px rgba(0, 20, 54, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#OneYear {
  border: 3px solid #ef4838;
}



.offerInfo:hover {
  transform: translateY(-10px);
  box-shadow: 0px 12px 24px rgba(0, 20, 54, 0.2), 0px 6px 12px rgba(0, 20, 54, 0.15);
}

.offerInfo h2 {
  font-size: 18px;
  color: #002349;
  margin-bottom: 10px;
  text-align: center;
}

#OneYear h2 {
  font-size: 18px;
  color: #ef4838;
  margin-bottom: 10px;
  text-align: center;
}

.offerInfo p {
  font-size: 15px;
  color: #333;
  text-align: center;
  margin-bottom: 15px;
}

.offerInfo ul {
  list-style: none;
  padding: 0;
  text-align: center;
}

.offerInfo li {
  font-size: 14px;
  color: #555;
  margin-bottom: 5px;
}

.price {
  font-weight: bold;
  color: #ef4838;
}

.ctaButton {
  width: 100%;
  padding: 12px;
  background-color: #002349;
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: auto;
}

.ctaButton:hover {
  background-color: #004080;
}

#OneYear .ctaButton {
  background-color: #ef4838;
}

#OneYear .ctaButton:hover {
  background-color: #e72514;
}