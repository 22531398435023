@use '../../assets/styles/mixins' as mixin;

.module {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 90px 90px auto;

    @include mixin.xs {
        margin: 50px;
    }
}

.module1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 3 colonnes */
    gap: 50px; /* Espacement entre les images */

    @include mixin.sm {
        grid-template-columns: repeat(2, 1fr); /* 2 colonnes sur tablettes */
        gap: 20px;
    }

    @include mixin.xs {
        grid-template-columns: repeat(1, 1fr); /* 1 colonne sur mobile */
        gap: 20px;
    }
}

.img {
    height: 250px;
    width: 100%;
    max-width: 400px;
    border-radius: 25px;

    @include mixin.xs {
        height: 150px;
        width: 100%;
    }
}
