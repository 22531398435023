@use '../../assets/styles/mixins' as mixin;
@use '../../assets/styles/debug';
@use '../../assets/styles/index.scss';


.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
  color: white;
  background-color: #f0f0f0;

  @include mixin.xs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  @include mixin.sm {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
}

.home {
  padding-top: 50px;
}

.header1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 50px;

  @include mixin.xl{
    padding: 50px 0 ;
    
  }


  @include mixin.sm {
    flex-direction: column;
    padding: 20px;
    display: flex;
  }

  @include mixin.xs {
    flex-direction: column;
    padding: 20px;
    display: flex;
  }

  @include mixin.md {
    flex-direction: column;
    margin: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
  
}

#headerVideo{
  
  margin-bottom: 50px;
  @include mixin.xs {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
    height: auto;
  }

  @include mixin.sm {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

 

  @include mixin.md {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  @include mixin.md-lt {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  @include mixin.lg {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
}

.videoContainer {
  width: 815px;
  height: 455px;
  border-radius: 20px;
  border:none;
  overflow: hidden;
  display: flex;
  margin-top: 50px;
  margin-bottom: 30px;

  @include mixin.xs {
    width: 350px;
    margin-top: 0px;
    margin-bottom: 0px;
    height: 255px;
  }

  @include mixin.sm {
    
    width: 500px;  
  }

  @include mixin.md {
    width: 700px; 
  }
}

.header2 {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 50px;
  padding-bottom: 50px;

  @include mixin.sm {
    flex-direction: column;
    margin: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  @include mixin.xs {
    flex-direction: column;
    margin: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
  }


  @include mixin.md {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
}

.drill {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.drill::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -20px;
  height: 2px;
  background-color: currentColor;

}

.h1 {
  color: #000000;
  padding: 60px;
  font-size: 50px;
  font-family: 'Inter', sans-serif;
  
  @include mixin.xl{
    padding: 0px;
    margin-right: 30px;
    font-size: 40px;
    
  }

  @include mixin.lg-lt {
    font-size: 40px;
    padding: 40px;
  }


  @include mixin.md {
    font-size: 40px;
    padding: 0px;
  }
}

.h2 {
  color: #000;
  font-family: 'Inter', sans-serif;
  font-size: 35.016px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;

  @include mixin.sm {
    font-size: 30px;
    padding: 15px;
  }

  @include mixin.xs {
    font-size: 30px;
    padding: 15px;
  }

}


.img1{
  width: 900px;
  border-radius: 15px;

  @include mixin.xs {
    width: 350px;
  }

  @include mixin.sm {
    
    width: 500px;  
  }

  @include mixin.md {
    width: 700px; 
  }
}

.infodrill {
  padding: 40px;

  @include mixin.xs {
    padding: 0px;
  }

  @include mixin.sm {
    padding: 0px;
  }
}


.img2 {
  width: 600px;
  height: 400px;
  flex-shrink: 0;
  border-radius: 10px;

  @include mixin.lg{
    width: 500px;
    height: 300px;
  }

  @include mixin.xs {
    width: 350px;
    height: 300px;
  }

  @include mixin.sm {
    width: 500px;
    height: 300px;
  }
}

.p1 {
  padding-top: 20px;

  @include mixin.md {
    text-align: center;
  }

  @include mixin.sm {
    text-align: center;
  }

  @include mixin.xs {
    text-align: center;
    font-size: 20px;
  }
}

.p2 {
  color: #818181;
  text-align: justify;
  font-family: 'Roboto';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
  width: 500px;
  text-align: justify;

  @include mixin.sm {
    font-size: 30px;
    padding: 15px;
  }

  @include mixin.xs {
    font-size: 30px;
    padding: 15px;
  }

  @include mixin.lg{
    width: 400px;
    font-size: 17px;
  }

  @include mixin.xs {
    width: 350px;
    font-size: 17px;
  }
}

.pinfodrill {
  display: flex;
  height: 12px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #8D8D8D;
  font-family: 'Inter', sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 2.5px;

  @include mixin.sm {
    
    padding: 15px;
  }

  @include mixin.xs {
    
    padding: 15px;
  }
}


.pinfodrill::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -10px;
  height: 2px;
  background-color: #002349;

  @include mixin.sm {
    
    left: 15px;
  }

  @include mixin.xs {
    
    left: 15px;
  }
}


.contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-shrink: 0;
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.btncours {
  width: 276.69px;
  height: 47px;
  border-radius: 50px;
  background: #002349;
  border: none;
  justify-content: center;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;
}

.question {
  padding-bottom: 150px;
}

.mail {
  flex-direction: column;
  justify-content: center;
  color: #000;
  font-family: 'Roboto';
  font-size: 16px;
  font-style: normal;
  line-height: normal;
  font-weight: 700;
  text-decoration-line: underline;
}

.faq {
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 50px;

  @include mixin.xs {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  @include mixin.sm {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
  @include mixin.md {
    padding-top: 30px;
    margin-left: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
}

.question {
  padding: 0 20px;
}

.details {
  font-size: 16px;
  transition: max-height 0.3s ease-out;
  margin-left: 50px;

  @include mixin.xs {
    padding-top: 30px;
    margin-left: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  @include mixin.sm {
    padding-top: 30px;
    margin-left: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }

  @include mixin.md {
    padding-top: 30px;
    margin-left: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
  }
}


.details summary {
  color: black;
  cursor: pointer;
}

.details details {
  border-radius: 10px;
  margin-bottom: 10px;
  background-color: white;
  width: 500px;
  padding-left: 30px;
  padding-right: 30px;
  font-family: 'Inter', sans-serif;
  text-align: justify;
  font-weight: bold;
  overflow: hidden;
  height: 60px; 
  transition: height 0.5s ease; 
  color: black;

  @include mixin.xs {
    width: 350px;
    padding-right: 0px;
  }
}

.details[open] {
  max-height: auto;
}
.details p {
  margin: 0;
  padding-top: 10px;
  color: black;
  font-size: 16px;
  font-weight: normal;
  padding-bottom: 10px;
}

summary {
  padding-top: 20px;
}