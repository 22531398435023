@use '../../assets/styles/mixins' as mixin;

footer {
  background-color: #002349;
  justify-content: center;
  align-items: center;
  color: white;
  display: flex;
  height: 200px;
  
  @include mixin.sm {
    flex-direction: column;
  }

  @include mixin.xs {
    flex-direction: column;
  }
}



.ul {
  list-style-type: none;
  /* Supprime les puces par défaut */
  margin: 0;
  padding: 0;
  padding-top: 10px;
  
}

.ul li {
  display: inline-block;
  padding: 10px;
  justify-content: center;
  align-items: center;
  text-align: center;


}

.footer1 {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;

  @include mixin.sm {
    flex-direction: column;
   
  }

  @include mixin.xs {
    flex-direction: column;
  }
}

.offers {
  @include mixin.xs {
    display: none;
  }

  @include mixin.sm {
    display: none;
  }
  @include mixin.md {
    display: none;
  }
}

.help {
  @include mixin.xs {
    display: none;
  }
  @include mixin.sm {
    display: none;
  }
  @include mixin.md {
    display: none;
  }
}

.pfooter {
  justify-content: center;
  text-align: center;
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 123.077% */
 
}

a {
  color: white;

}

.fa {
  font-size: 25px;
}

i:hover {
  color: var(--primary);
  width: 28px;
  height: 28px;
}

i {
  width: 28px;
  height: 28px;
  cursor: pointer;
}

.fa {
  color: white;
  width: 28px;
  height: 28px;

  &:hover {
    color: #ef4838;
  }
}

.cookie {
  display: flex;
  flex-direction: row;
}

.cookie p {
  padding-right: 20px;
}

.info {
  justify-content: center;
  align-items: center;
  margin-left: 100px;
  margin-right: 100px;
  text-align: center;
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 123.077% */
}

.pinfo {
  justify-content: center;
  text-align: center;
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 123.077% */
}

.p15 {
  color: #FFF;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 120% */
}
