@use '../../assets/styles/mixins' as mixin;
@use '../../assets/styles/debug';
@use '../../assets/styles/index.scss';


.h1 {
  color: #002349;
  font-size: 23px;
  font-weight: bold;
}

.lessonDetails {
  display: flex;
  flex-direction: column;
  padding-left: 200px;
  padding-top: 50px;

  @include mixin.xl{
    padding-left: 100px;
  }

  
  @include mixin.lg {
    padding-left: 60px;
  }

  @include mixin.md-lt {
    padding-left: 30px;
  }

  @include mixin.sm-lt {
    padding-left: 20px;
  }

  @include mixin.xs {
    padding-left: 10px;
  }
}

.description {
  color: #002349;
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 10px;
}

.littleDescription {
  color: #002349;
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

.prof{
  width: 250px;
  margin-left: 100px;
  border-radius: 10px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  height: 320px;
  border: 2px solid #001436;

  @include mixin.xl{
    margin-right: 50px;
  }

  @include mixin.lg {
    margin-left: 30px;
  }

  @include mixin.md-lt {
    margin-left: 40px;
  }

  @include mixin.sm-lt {
    margin-left: 40px;
  }

  @include mixin.xs {
    margin-left: 40px;
  }
}

.Thiago {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-left: 20px;
  margin-right: 15px;
}

.name {
  font-size: 17px;
}

.info {
  color: #001436;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  margin-left: 30px;
  padding-top: 0;
  margin-top: 0;
}

.profBanner {
  padding-top: 0;
  margin-top: 0;
  border-radius: 5px 5px 0 0;
  height: 80px;
  width: 100%;
  background-color: #001436;
  display: flex;
  align-items: center;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  padding-top: 10px;
}

.linkButton {
  padding: 10px 20px;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  height: 47px;
  border-radius: 50px;
  background: #002349;
  border: none;
  justify-content: center;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
}


.linkButton:hover {
  background-color: #005bb5;
}


.info1 {
  color: #001436;
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: bold;
  margin-left: 30px;
  margin-top: 30px;
  
}

.videoProf {
  display: flex;

  @include mixin.lg {
    flex-direction: column;
  }

  @include mixin.md-lt {
    flex-direction: column;
  }

  @include mixin.sm-lt {
    flex-direction: column;
  }

  @include mixin.xs {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @include mixin.xs {
    margin-top: 0px;
    margin-bottom: 30px;
  }

  

  
}

.abonnement{
  color: #ef4838;
  font-size: 18px;
}

.descriptionTitle {
  color: #002349;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
}

.recommandedTitle {
  color: #002349;
  font-size: 22px;
  font-weight: bold;
  margin: 0;
  padding-bottom: 20px;
}

.videoContainer {
  width: 815px;
  height: 455px;
  border-radius: 20px;
  border:none;
  overflow: hidden;
  display: flex;
  margin-top: 50px;
  margin-bottom: 30px;

  @include mixin.xs {
    width: 350px;
    height: 355px;
    margin: 0;
    padding: 0;
  }

  @include mixin.sm {
    
    width: 500px;  
  }

  @include mixin.md {
    width: 700px; 
  }
}

.video {
 width: 100%;
 height: 100%;
}

.recommanded {
  padding-top: 30px;
  padding-bottom: 60px;
}

.recommendedLessons {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;

  @include mixin.md-lt {
    justify-content: center;
  }

  @include mixin.sm-lt {
    justify-content: center;
  }

  @include mixin.xs {
    justify-content: center;
  }
}


.lessonPrice {
  width: 866px;
  border: 1px solid #002349;
  border-radius: 10px;
  font-family: 'Inter', sans-serif;
  background-color: rgba(255, 255, 255, 1);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  position: relative;

  @include mixin.md-lt {
    flex-direction: column;
    width: 766px;
  }

  @include mixin.sm-lt {
    flex-direction: column;
    width: 706px;
  }

  @include mixin.xs {
    flex-direction: column;
    width: 346px;
  }
}

.priceContainer {
  height: 153px;
  width: 100%;
  background-color: #002349;
  display: flex;
  align-items: center;
}

.price {
  font-size: 23px;
  font-weight: 200;
  color: white;
  padding-left: 40px;
}

.price span {
  font-size: 42px;
  font-weight: bold;
  font-family: 'Anton', sans-serif;
}

.textContainer {
  height: 300px;
  padding-top: 20px;

  

}

.title {
  font-size: 16px;
  color: #002349;
  font-weight: 400;
  padding-left: 40px;

  @include mixin.xs {
    padding-left: 30px;
  }
}

.professor {
  font-size: 12px;
  font-weight: 400;
  color: #002349;
  margin-top: 5px;
}

.professor span {
  font-weight: 600;
  color: #002349;
}

.divTime {
  position: absolute;
  bottom: 0px;
  left: 15px;
  display: flex;
  align-items: center;
  /* Aligne verticalement l'icône et le texte */
}

.fa {
  color: #002349;
  padding-top: 10px;
}

.time {
  color: #002349;
  font-size: 13px;
  font-weight: bold;
}


.btncours {
  width: 89%;
  height: 47px;
  border-radius: 50px;
  background: #002349;
  margin-left: 40px;
  border: none;
  justify-content: center;
  flex-shrink: 0;
  color: #FFF;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  margin-top: 20px;
  cursor: pointer;

  @include mixin.xs {
    margin-left: 20px;
  }
}
