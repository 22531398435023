@use '../../assets/styles/mixins' as mixin;
@use '../../assets/styles/debug';
@use '../../assets/styles/index.scss';


.MainNav {
  height: auto;
  display: flex;
  position: relative;
  background-color: #f0f0f0;
  margin-bottom: 0;
  padding-bottom: 0;
  justify-content: center;
  align-items: center;

 
}

img {
  height: 80px;
  width: 230px;
  cursor: pointer;
}

.nav {
  display: flex;
  align-items: center;
  flex-direction: row;
  width: 100%;
  max-width: 1330px;
  height: auto;
  justify-content: space-between;
  padding:0 20px 0 20px;

  @include mixin.sm {
    height: auto;
  }

  @include mixin.xs {
    flex-direction: column;
    height: auto;
  }

  @include mixin.md {
    flex-direction: row;
    height: auto;
  }

  @include mixin.lg-lt {
    max-width: 830px;
  }
}

.searchForm {
  display: flex;
  width: 440px;
  padding: 8px 18px 6px 17px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 50px;
  border: 1px solid #DEDFEC;
  background: #FFF;
  margin: 0 auto;

  @include mixin.sm {
    width: auto;
  }

  @include mixin.md-lt {
    width: auto;
  }
  @include mixin.lg {
    width: auto;
  }
  @include mixin.xs {
    width: auto;
  }
}

.searchInput {
  flex-grow: 1;
  padding: 0 10px;
  border: none;
  outline: none;
  color: #959CB0;
  font-family: 'Inter', sans-serif;
  font-size: 15.125px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.searchButton {
  display: flex;
  width: 28px;
  height: 28px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 50%;
  background: #002349;
  border: none;
  cursor: pointer;
}

.searchResultsContainer {
  position: absolute;
  top: 70%;
  background-color: white;
  border: 1px solid #ccc;
  z-index: 1050;
  border-radius: 15px;
  max-height: 500px; /* Limiter la hauteur des résultats */
  overflow-y: auto; /* Ajouter un scroll si les résultats dépassent la hauteur */
  box-sizing: border-box;
}

.searchResultItem {
  padding: 10px;
  border-bottom: 1px solid #eee;
}

.searchResultItem:hover {
  background-color: #002349;
}

.searchResultItem:hover a {
  color: white;  
}

.searchResultItem a {
  display: block; /* Le lien prend toute la largeur */
  width: 100%;    /* Forcer le lien à occuper toute la largeur */
  text-decoration: none;
  color: #002349;
  padding: 10px;  /* Ajouter un padding pour qu'il ressemble à un bloc */
}

.searchButton svg {
  fill: white;
}

.links {
  display: flex;
  gap: 20px;


  @include mixin.sm {
    padding-top: 20px;
  }

  @include mixin.xs {
    flex-direction: row;
    padding-top: 20px;
  }
}

.nav a {
  color: #001436;
  text-align: center;
  font-family: 'Inter', sans-serif;
  font-size: 14.875px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  text-decoration: none;
  @include mixin.sm {
    font-size: 18px;
  }
}

.btn {
  color: #ef4838;
  background: none;
  border: none;
  font-family: 'Inter', sans-serif;
  font-size: 14.875px;
  font-style: normal;
  cursor: pointer;
}

.profileMenu {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center; // Centrer le texte et l'icône
}

.dropdownIcon {
  margin-left: 8px; // Espacement entre le nom de l'utilisateur et l'icône
  transition: transform 0.3s ease; // Ajout d'une transition pour l'effet de rotation
}

.dropdownMenu {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.dropdownMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdownMenu ul li {
  padding: 10px 20px;
  cursor: pointer;
}

.dropdownMenu ul li:hover {
  background-color: #f0f0f0;
}

.show {
  display: block;
}

.show .dropdownIcon {
  transform: rotate(180deg); // Rotation de l'icône quand le menu est ouvert
}
