@use './base';
@use './variables';
@use './flex';
@use './utiles';
@use './debug';
@use './themes';

html {
  scroll-behavior: smooth;
}
